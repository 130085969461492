import React, { useContext, useCallback, useMemo, useState, useEffect } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { ChevronDown, ChevronRight } from 'lucide-react';
import CodeRenderer from './CodeRenderer';
import { AppContext } from '../../../context/AppContext';
import ReactMarkdown from 'react-markdown';

// Helper function to extract parent library from file path
const getParentLibrary = (filePath) => {
  const pathParts = filePath.split('/');
  // Assuming the parent library is the second segment in the path
  return pathParts.length > 2 ? pathParts[1] : 'Unknown Library';
};

// Summary of Changes Component
const SummaryOfChanges = ({ summary }) => {
  return (
    <div className="bg-gray-800 border border-gray-700 rounded-lg mb-4">
      <div className="p-4">
        <h2 className="text-xl font-bold mb-2">Summary of Changes</h2>
        <pre className="whitespace-pre-wrap text-sm text-gray-300">{summary}</pre>
      </div>
    </div>
  );
};

// Changes Section Component
const ChangesSection = ({ changes }) => {
  return (
    <div className="bg-gray-800 p-4 rounded-lg h-full">
      <h5 className="text-lg font-bold mb-3 text-blue-300">Suggested Changes:</h5>
      <ReactMarkdown className="text-white prose prose-invert max-w-none">
        {changes}
      </ReactMarkdown>
    </div>
  );
};

// Update CodeRenderer component props
const CodeBlock = ({ filePath, codeChanges, highlights }) => {
  console.log('CodeBlock inputs:', {
    filePath,
    codeChanges,
    highlights
  });

  return (
    <div className="bg-gray-800 p-4 rounded-lg mb-4">
      <CodeRenderer 
        filePath={filePath}
        codeChanges={codeChanges?.code_changes || []}
        highlights={highlights}
      />
    </div>
  );
};

// Helper function to get the last two folders
const getLastTwoFolders = (filePath) => {
  const parts = filePath.split('/');
  const folders = parts
    .slice(0, -1)  // Remove the filename
    .filter(part => part.length > 0);  // Remove empty parts
  
  if (folders.length < 2) {
    return folders.join('/');
  }
  
  return `${folders[folders.length - 2]}/${folders[folders.length - 1]}`;
};

// Updated FileNameDisplay component
const FileNameDisplay = ({ filePath }) => {
  const pathParts = filePath.split('/');
  const fileName = pathParts.pop(); // Get the last part (filename)
  const lastTwoFolders = getLastTwoFolders(filePath);

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <span className="font-mono font-bold text-xl text-white">{fileName}</span>
        {lastTwoFolders && (
          <span className="px-2 py-1 text-xs bg-gray-600 text-blue-300 rounded">
            /{lastTwoFolders}
          </span>
        )}
      </div>
      <span className="text-gray-400 text-xs">/{pathParts.join('/')}</span>
    </div>
  );
};

// Dependency Card Component
const DependencyCard = ({ dependency }) => {
  const { expandedCards, toggleCard } = useContext(AppContext);
  const isExpanded = expandedCards.includes(dependency.file_path);

  console.log('DependencyCard rendering:', {
    filePath: dependency.file_path,
    isExpanded,
    description: dependency.description,
    codeChanges: dependency.code_changes
  });

  return (
    <div className="dependency-card mb-4 bg-gray-700 rounded-lg overflow-hidden border border-gray-600">
      <div 
        className="p-4 bg-gray-600 cursor-pointer hover:bg-gray-500"
        onClick={() => toggleCard(dependency.file_path)}
      >
        <div className="flex items-center space-x-2">
          <span className="text-white">
            {isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
          </span>
          <FileNameDisplay filePath={dependency.file_path} />
        </div>
      </div>
      
      {isExpanded && (
        <div className="p-4">
          <div className="bg-gray-800 p-4 rounded-lg mb-4">
            <h5 className="text-lg font-bold mb-3 text-blue-300">
              Impact Description:
            </h5>
            <ReactMarkdown className="text-white prose prose-invert max-w-none">
              {dependency.description}
            </ReactMarkdown>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <CodeBlock 
              filePath={dependency.file_path}
              codeChanges={dependency.code_changes}
              highlights={dependency.code_changes}
            />
          </div>
        </div>
      )}
    </div>
  );
};

// Add a new helper function to extract the parent folder name
const getParentFolder = (filePath) => {
  const parts = filePath.split('/');
  // Look for the parent folder in the path
  for (let i = parts.length - 2; i >= 0; i--) {
    if (parts[i] && !parts[i].includes('.')) {
      return parts[i];
    }
  }
  return '';
};

// Add this new component with simplified design
const RelevanceAssessment = ({ assessment }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  if (!assessment) return null;

  return (
    <div 
      className="mt-2 cursor-pointer group"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex items-center gap-2 text-gray-300">
        <span className="text-gray-500 group-hover:text-gray-300 transition-colors">
          {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        </span>
        <div className={`text-sm ${isExpanded ? '' : 'line-clamp-3'}`}>
          <ReactMarkdown className="prose prose-invert max-w-none">
            {assessment}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

// Main File Card Component
const FileCard = ({ file, findRelevantDependencies }) => {
  const { expandedCards, toggleCard, analysisContent, accumulatedFiles } = useContext(AppContext);
  const isExpanded = expandedCards.includes(file.file_path);
  
  // Normalize the file path
  const normalizedFilePath = file.file_path.replace(/^\/+/, '');
  
  // Get dependencies for this file
  const fileDependencies = useMemo(() => 
    findRelevantDependencies(normalizedFilePath),
    [normalizedFilePath, findRelevantDependencies]
  );

  // Find the change data for this file
  const changeData = analysisContent?.changes?.find(change => 
    change.file_path === file.file_path
  );

  // Get relevance assessment from accumulated files
  const relevanceAssessment = accumulatedFiles[normalizedFilePath]?.assessment_of_relevance;

  const parentFolder = getParentFolder(file.file_path);

  return (
    <div className="file-card mb-4 bg-gray-800 rounded-lg overflow-hidden border border-gray-700">
      <div className="p-4 bg-gray-700/50 border-b border-gray-600">
        <div className="flex justify-between items-start">
          <button 
            onClick={() => toggleCard(file.file_path)} 
            className="text-white hover:text-blue-400 mt-1"
          >
            {isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
          </button>
          
          <div className="flex-grow flex items-start justify-between ml-2">
            <FileNameDisplay filePath={file.file_path} />
            
            {fileDependencies?.length > 0 && (
              <div className="px-3 py-1 bg-blue-600 text-white text-sm rounded-full">
                {fileDependencies.length} Dependencies
              </div>
            )}
          </div>
        </div>

        {relevanceAssessment && (
          <div className="mt-2 pl-7">
            <RelevanceAssessment assessment={relevanceAssessment} />
          </div>
        )}
      </div>
      
      {/* Expanded Content */}
      {isExpanded && (
        <div className="p-4">
          <div className="bg-gray-900/50 rounded-lg border border-gray-600 p-4 mb-6">
            <PanelGroup direction="horizontal" className="min-h-[300px]">
              <Panel defaultSize={40} minSize={30}>
                <div className="h-full pr-2">
                  <ChangesSection changes={file.description} />
                </div>
              </Panel>
              
              <PanelResizeHandle className="panel-resize-handle-subtle" />
              
              <Panel defaultSize={60} minSize={40}>
                <div className="h-full pl-2">
                  <CodeBlock 
                    filePath={normalizedFilePath}
                    codeChanges={changeData}
                    highlights={[]}
                  />
                </div>
              </Panel>
            </PanelGroup>
          </div>

          {/* Dependencies Section */}
          {Boolean(fileDependencies?.length) && (
            <div className="bg-gray-900/50 rounded-lg border border-gray-600 p-4">
              <h4 className="text-lg font-semibold text-white mb-4">
                Impacted Dependencies ({fileDependencies.length})
              </h4>
              <div className="space-y-4">
                {fileDependencies.map((dependency, index) => {
                  console.log('Mapping dependency:', index, dependency);
                  return (
                    <DependencyCard 
                      key={dependency.file_path} 
                      dependency={dependency}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Main Analysis Component
const AnalysisView = () => {
  const { analysisContent, dependencyImpactContent } = useContext(AppContext);
  
  // Add state to track if we've already processed the data
  const [isProcessed, setIsProcessed] = useState(false);
  
  useEffect(() => {
    // Only process once when analysisContent is available
    if (analysisContent && !isProcessed) {
      console.log('Processing Analysis Data:', analysisContent);
      setIsProcessed(true);
    }
  }, [analysisContent, isProcessed]);

  // Update the findRelevantDependencies function to use dependencyImpactContent
  const findRelevantDependencies = useCallback((filePath) => {
    console.log('Finding dependencies for:', filePath);
    console.log('Available dependency_impacts:', dependencyImpactContent);
    
    // Remove any leading slashes for consistent comparison
    const normalizedFilePath = filePath.replace(/^\/+/, '');
    
    const deps = dependencyImpactContent?.dependency_impacts?.filter(
      dep => dep.key_change_file_path === normalizedFilePath
    ) || [];
    
    console.log('Found dependencies:', deps);
    return deps;
  }, [dependencyImpactContent]);

  const keyChangeFiles = useMemo(() => {
    if (!analysisContent?.changes) {
      console.log('No changes data available');
      return [];
    }
    
    return analysisContent.changes.map(change => ({
      file_path: change.file_path,
      description: change.description
    }));
  }, [analysisContent]);

  if (!analysisContent) {
    return <div className="p-4 text-gray-400">Loading analysis...</div>;
  }

  return (
    <div className="h-full">
      <SummaryOfChanges summary={analysisContent.summary} />
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-4">Key Changes</h2>
        {keyChangeFiles.map(file => (
          <FileCard 
            key={file.file_path} 
            file={file}
            findRelevantDependencies={findRelevantDependencies}
          />
        ))}
      </div>
    </div>
  );
};

// Wrapped Export
const AskAnalysis = () => {
  return <AnalysisView />;
};

export default AskAnalysis;