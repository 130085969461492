import React from 'react';
import GraphDisplay from '../components/graphs/GraphDisplay';

const GraphDataVisualization = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <h1 className="text-2xl font-bold mb-4">Graph Data Visualization</h1>
      <div className="flex">
        <div className="flex-grow" style={{ height: '600px' }}>
          <GraphDisplay height="100%" />
        </div>
      </div>
    </div>
  );
};

export default GraphDataVisualization;
