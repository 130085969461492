import React, { useState, useContext } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Explorer from "../../components/Explorer/Explorer";
import GraphDisplay from "../../components/graphs/GraphDisplay";
import AskAnalysis from './FeatureRequest/ask_analysis';
import { AppContext } from '../../context/AppContext';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const Analysis = () => {
  const { 
    featureRequestText, 
    setFeatureRequestText
  } = useContext(AppContext);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const location = useLocation();
  

  const handleAnalysisTextChange = (newText) => {
    setFeatureRequestText(newText);
    setHasChanged(true);
  };

  // Create featureRequestProps object
  const featureRequestProps = {
    analysisText: featureRequestText,
    isEditing,
    setIsEditing,
    onAnalysisTextChange: handleAnalysisTextChange,
    hasChanged
  };

  return (
    <div className="h-screen bg-gray-900 p-4">
      <PanelGroup direction="horizontal" className="h-full">
        {/* Left Panel - Explorer and Graph */}
        <Panel defaultSize={30} minSize={20} className="flex flex-col">
          <PanelGroup direction="vertical">
            {/* Explorer Panel */}
            <Panel defaultSize={50} minSize={20}>
              <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                <Explorer 
                  selectedFiles={location.state?.selectedFiles || []}
                />
              </div>
            </Panel>

            <PanelResizeHandle className="h-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

            {/* Graph Panel */}
            <Panel defaultSize={50} minSize={20}>
              <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                <GraphDisplay showLegend={true} />
              </div>
            </Panel>
          </PanelGroup>
        </Panel>

        <PanelResizeHandle className="w-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

        {/* Right Panel - Content */}
        <Panel defaultSize={70} minSize={40}>
          <div className="h-full pl-4 flex flex-col overflow-hidden">
            <div className="bg-gray-800 rounded-lg border border-gray-700 p-4 mb-4">
              <RequestedFeature {...featureRequestProps} />
            </div>
            <div className="flex-1 bg-gray-800 rounded-lg border border-gray-700 p-4 overflow-auto">
              <AskAnalysis />
            </div>
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
};

const RequestedFeature = ({ 
  analysisText,
  isEditing, 
  setIsEditing, 
  onAnalysisTextChange
}) => {
  RequestedFeature.propTypes = {
    analysisText: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    setIsEditing: PropTypes.func.isRequired,
    onAnalysisTextChange: PropTypes.func.isRequired,
    hasChanged: PropTypes.bool.isRequired
  };

  return (
    <div className="bg-gray-800 border border-gray-700 rounded-lg">
      <div className="p-4">
        <div className="mb-2">
          <h2 className="text-xl font-bold">Requested Feature</h2>
        </div>
        <div 
          className={`bg-gray-900 rounded-lg p-4 ${isEditing ? 'border border-blue-500' : ''}`}
          onClick={() => !isEditing && setIsEditing(true)}
        >
          {isEditing ? (
            <textarea
              className="w-full bg-transparent text-white resize-none focus:outline-none"
              value={analysisText}
              onChange={(e) => onAnalysisTextChange(e.target.value)}
              onBlur={() => setIsEditing(false)}
              autoFocus
            />
          ) : (
            <pre className="text-white whitespace-pre-wrap">{analysisText}</pre>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analysis;
