import React, { useState, useContext } from 'react';
import { ChevronRight, ChevronDown } from 'lucide-react';
import { AppContext } from '../../context/AppContext';
import Tooltip from './Tooltip';
import { getFileIcon } from './fileIcons';

const Explorer = ({ onFileSelect, selectedFiles, files }) => {
  const { relevantFilesContent, accumulatedFiles } = useContext(AppContext);
  
  // Get files from props or context
  const files_array = files 
    ? Object.values(files)
    : accumulatedFiles && Object.keys(accumulatedFiles).length > 0
      ? Object.values(accumulatedFiles)
      : relevantFilesContent?.Files 
        ? Object.values(relevantFilesContent.Files)
        : [];

  // Updated helper function to check if a folder should be collapsed
  const shouldCollapseFolder = (node) => {
    // 1. Must be a folder
    if (node.type !== 'folder') return false;
    
    // 2. Must have exactly one child
    if (!node.children || node.children.length !== 1) return false;
    
    // 3. That child must be a folder
    const childNode = node.children[0];
    return childNode.type === 'folder';
  };

  // Helper function to get the collapsed path of nested folders
  const getCollapsedPath = (node) => {
    let path = [node.name];
    let current = node;
    
    // Keep collapsing until we reach a folder that shouldn't be collapsed
    while (current.children && shouldCollapseFolder(current)) {
      current = current.children[0];
      path.push(current.name);
    }
    
    return {
      displayPath: path.join('/'),
      finalNode: {
        ...current,
        name: path.join('/'),
        isCollapsed: true
      }
    };
  };

  // Updated buildFileTree function
  const buildFileTree = (files) => {
    const root = { type: 'root', children: {} };
    
    files.forEach(file => {
      const pathParts = file.file_path.split('/');
      let current = root.children;
      
      pathParts.forEach((part, index) => {
        if (index === pathParts.length - 1) {
          // Create file node
          current[part] = {
            name: part,
            type: 'file',
            path: file.file_path,
            relevancy: file.relevancy,
            description: file.textual_representation
          };
        } else {
          // Create folder node
          if (!current[part]) {
            current[part] = {
              name: part,
              type: 'folder',
              children: {},
              hasFiles: false
            };
          }
          current = current[part].children;
        }
      });
    });

    // Mark folders that contain files directly
    const markDirectFiles = (node) => {
      if (node.type === 'folder') {
        node.hasDirectFiles = node.children?.some(child => child.type === 'file');
        node.children?.forEach(markDirectFiles);
      }
      return node;
    };

    const convertToArray = (node) => {
      if (node.type === 'folder' || node.type === 'root') {
        node.children = Object.values(node.children)
          .map(child => convertToArray(child))
          .sort((a, b) => {
            if (a.type !== b.type) return a.type === 'folder' ? -1 : 1;
            return a.name.localeCompare(b.name);
          });
      }
      return node;
    };

    const tree = convertToArray(root);
    return markDirectFiles(tree);
  };

  // Add this helper function to get relevancy class
  const getRelevancyClass = (node) => {
    if (node.type === 'file' && node.relevancy === 'likely') {
      return 'bg-green-800/30 rounded px-1';  // Semi-transparent green background
    }
    return '';
  };

  // Updated FileTreeNode component
  const FileTreeNode = ({ node, level = 0, path = '' }) => {
    // Initialize expansion state based on whether it's a folder that shouldn't be collapsed
    const [isExpanded, setIsExpanded] = useState(
      node.type === 'folder' && !shouldCollapseFolder(node)
    );
    
    // Skip root node rendering
    if (node.type === 'root') {
      return (
        <div>
          {node.children?.map((childNode, index) => (
            <FileTreeNode 
              key={index} 
              node={childNode} 
              level={level} 
              path={path} 
            />
          ))}
        </div>
      );
    }

    // Handle collapsed folders
    if (shouldCollapseFolder(node)) {
      const { displayPath, finalNode } = getCollapsedPath(node);
      return (
        <FileTreeNode 
          node={{
            ...finalNode,
            name: displayPath,
            originalPath: path + '/' + displayPath
          }}
          level={level}
          path={path}
        />
      );
    }

    const currentPath = node.path || `${path}/${node.name}`.replace(/^\//, '');
    const hasChildren = node.type === 'folder' && node.children?.length > 0;
    
    const nodeContent = (
      <div
        className="flex items-center cursor-pointer hover:bg-gray-800 py-1 px-2 rounded group"
        onClick={() => hasChildren && setIsExpanded(!isExpanded)}
        style={{ paddingLeft: `${level * 16 + (node.type === 'file' ? 16 : 0)}px` }}
        data-path={currentPath}
        data-type={node.type}
      >
        <div className="flex items-center gap-1 min-w-0 w-full">
          {hasChildren && (
            <span className="text-gray-500 flex-shrink-0">
              {isExpanded ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
            </span>
          )}
          <div className={`flex items-center gap-1.5 min-w-0 ${getRelevancyClass(node)}`}>
            <span className="flex-shrink-0">
              {getFileIcon(node)}
            </span>
            <span className={`text-sm ${node.type === 'folder' ? 'font-medium' : ''} truncate break-all`}>
              {node.name}
            </span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="mb-1">
        {node.type === 'file' && node.description ? (
          <Tooltip content={node.description}>
            {nodeContent}
          </Tooltip>
        ) : nodeContent}
        
        {isExpanded && node.children && (
          <div>
            {node.children.map((childNode, index) => (
              <FileTreeNode 
                key={index} 
                node={childNode} 
                level={level + 1} 
                path={currentPath} 
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  const fileTree = buildFileTree(files_array);

  return (
    <div className="h-full flex flex-col">
      <h2 className="text-sm font-semibold p-4 text-gray-400 uppercase bg-gray-800/50 border-b border-gray-700">
        Explorer (Possibly relevant files)
      </h2>
      <div className="flex-1 overflow-auto p-4">
        <FileTreeNode node={fileTree} />
      </div>
    </div>
  );
};

export default Explorer;
