import React, { useState, useEffect, useContext } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { AppContext } from '../../../context/AppContext';
import './CodeRenderer.css';

const CodeRenderer = ({ codeChanges, filePath, highlights, isDependency }) => {
  const [expandedSections, setExpandedSections] = useState(new Set());
  const [fullCode, setFullCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showDiff, setShowDiff] = useState(true);
  const { getCode } = useContext(AppContext);

  const toggleSection = (sectionId) => {
    setExpandedSections(prev => {
      const newSet = new Set(prev);
      if (newSet.has(sectionId)) {
        newSet.delete(sectionId);
      } else {
        newSet.add(sectionId);
      }
      return newSet;
    });
  };

  useEffect(() => {
    const fetchCode = async () => {
      const componentId = `CodeRenderer_${Date.now()}`;
      console.log(`🚀 [${componentId}] Starting code fetch for:`, filePath);
      setIsLoading(true);
      
      try {
        const cleanPath = filePath.replace(/^\/+/, '');
        console.log(`📨 [${componentId}] Calling getCode function with path:`, cleanPath);
        
        const codeContent = await getCode(cleanPath);
        console.log(`✅ [${componentId}] Received code content length:`, codeContent?.length || 0);
        
        if (codeContent) {
          setFullCode(codeContent);
        } else {
          console.error(`❌ [${componentId}] No content received for:`, cleanPath);
          setFullCode('');
        }
      } catch (error) {
        console.error(`❌ [${componentId}] Error fetching code:`, error);
        setFullCode('');
      } finally {
        setIsLoading(false);
      }
    };

    if (filePath) {
      fetchCode();
    }
  }, [filePath, getCode]);

  const processCode = (code, changes) => {
    if (!code) return [];
    
    const lines = code.split('\n');
    let processedLines = [];
    
    // Create maps to track different types of lines
    const changedLines = new Map();
    const visibleLines = new Set();
    const contextSize = 1;

    // First pass: collect all changes and sort them by line number
    const sortedChanges = Array.isArray(changes) 
      ? [...changes].sort((a, b) => a.row_num - b.row_num) 
      : [];

    // Group consecutive additions
    const groupedChanges = [];
    let currentGroup = null;

    sortedChanges.forEach(change => {
      if (change.change_type === 'add') {
        if (currentGroup && currentGroup.lastRow === change.row_num - 1) {
          // Add to current group
          currentGroup.changes.push(change);
          currentGroup.lastRow = change.row_num;
        } else {
          // Start new group
          if (currentGroup) {
            groupedChanges.push(currentGroup);
          }
          currentGroup = {
            type: 'add',
            startRow: change.row_num,
            lastRow: change.row_num,
            changes: [change]
          };
        }
      } else {
        if (currentGroup) {
          groupedChanges.push(currentGroup);
          currentGroup = null;
        }
        groupedChanges.push(change);
      }
    });
    if (currentGroup) {
      groupedChanges.push(currentGroup);
    }

    // Track line number adjustments
    let lineAdjustment = 0;

    // Process changes and mark context lines
    groupedChanges.forEach(change => {
      if (change.type === 'add') {
        // Handle grouped additions
        const lineNum = change.startRow;
        change.changes.forEach(addition => {
          changedLines.set(addition.row_num, {
            type: 'add',
            content: addition.code,
            groupInfo: change
          });
        });
        
        // Mark context for the group
        for (let i = Math.max(1, lineNum - contextSize); 
             i <= Math.min(lines.length, lineNum + contextSize); i++) {
          visibleLines.add(i);
        }
      } else {
        // Handle other changes normally
        const lineNum = change.row_num;
        changedLines.set(lineNum, {
          type: change.change_type,
          content: change.code
        });
        
        for (let i = Math.max(1, lineNum - contextSize); 
             i <= Math.min(lines.length, lineNum + contextSize); i++) {
          visibleLines.add(i);
        }
      }
    });

    let currentSection = null;
    let hiddenCount = 0;

    // Process each line
    lines.forEach((line, index) => {
      const originalLineNumber = index + 1;
      const adjustedLineNumber = originalLineNumber + lineAdjustment;
      const changeInfo = changedLines.get(originalLineNumber);
      const isVisible = visibleLines.has(originalLineNumber);

      if (!isVisible) {
        hiddenCount++;
        if (currentSection === null) {
          currentSection = `section-${processedLines.length}`;
        }
      } else {
        if (hiddenCount > 0) {
          processedLines.push({
            type: 'expand',
            count: hiddenCount,
            section: currentSection,
            startLine: adjustedLineNumber - hiddenCount,
            endLine: adjustedLineNumber - 1
          });
          hiddenCount = 0;
          currentSection = null;
        }

        if (showDiff) {
          if (changeInfo?.type === 'del') {
            processedLines.push({
              lineNumber: adjustedLineNumber,
              content: line,
              status: 'del',
              isVisible: true,
              section: null
            });
            lineAdjustment--;
          } else {
            // Show the original line without highlighting
            processedLines.push({
              lineNumber: adjustedLineNumber,
              content: line,
              status: 'unchanged',
              isVisible: true,
              section: null
            });

            // Handle grouped additions
            if (changeInfo?.type === 'add' && changeInfo.groupInfo?.startRow === originalLineNumber) {
              // Add all lines in the group at once
              changeInfo.groupInfo.changes.forEach((addition, i) => {
                processedLines.push({
                  lineNumber: adjustedLineNumber + 1 + i,
                  content: addition.code,
                  status: 'add',
                  isVisible: true,
                  section: null
                });
              });
              lineAdjustment += changeInfo.groupInfo.changes.length;
            }
          }
        } else {
          // Highlighting mode remains unchanged
          processedLines.push({
            lineNumber: adjustedLineNumber,
            content: line,
            status: changeInfo ? 'highlighted' : 'unchanged',
            isVisible: true,
            section: null
          });
        }
      }
    });

    // Add final expand section if needed
    if (hiddenCount > 0) {
      processedLines.push({
        type: 'expand',
        count: hiddenCount,
        section: currentSection,
        startLine: lines.length + lineAdjustment - hiddenCount + 1,
        endLine: lines.length + lineAdjustment
      });
    }

    return processedLines;
  };

  const processedCode = processCode(fullCode, codeChanges);

  if (isLoading) {
    return <div className="loading-text">Loading code...</div>;
  }

  return (
    <div className="code-renderer">
      {isLoading ? (
        <div className="loading-text">Loading code...</div>
      ) : (
        <div className="code-block-wrapper">
          {!isDependency && (
            <div className="code-renderer-header">
              <div className="toggle-button-group">
                <button
                  onClick={() => setShowDiff(true)}
                  className={`toggle-button ${
                    showDiff ? 'toggle-button-active' : 'toggle-button-inactive'
                  }`}
                >
                  Code Suggestions
                </button>
                <button
                  onClick={() => setShowDiff(false)}
                  className={`toggle-button ${
                    !showDiff ? 'toggle-button-active' : 'toggle-button-inactive'
                  }`}
                >
                  Highlights
                </button>
              </div>
            </div>
          )}
          <table className="code-table">
            <tbody className="code-table-body">
              {processedCode.map((line, index) => {
                if (line.type === 'expand') {
                  const isExpanded = expandedSections.has(line.section);
                  
                  if (isExpanded) {
                    const hiddenLines = [];
                    for (let i = line.startLine; i <= line.endLine; i++) {
                      hiddenLines.push(
                        <tr key={`hidden-${i}`} className="code-line">
                          <td className="line-number">
                            {i}
                          </td>
                          <td className="code-content code-content-unchanged">
                            <code className="block truncate">
                              {fullCode.split('\n')[i - 1]}
                            </code>
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <React.Fragment key={`section-${index}`}>
                        {hiddenLines}
                        <tr className="expand-row"
                            onClick={() => toggleSection(line.section)}>
                          <td colSpan="2" className="expand-text">
                            <ChevronDown className="expand-icon" size={16} />
                            {`${line.count} less lines`}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                  
                  return (
                    <tr key={`expand-${index}`} 
                        className="expand-row"
                        onClick={() => toggleSection(line.section)}>
                      <td colSpan="2" className="expand-text">
                        <ChevronRight className="expand-icon" size={16} />
                        {`${line.count} more lines`}
                      </td>
                    </tr>
                  );
                }

                return (
                  <tr key={`line-${index}`} className="code-line">
                    <td className={`line-number ${
                      line.status === 'add' ? 'bg-green-500/20 text-green-400' :
                      line.status === 'del' ? 'bg-red-500/20 text-red-400 line-strikethrough' : ''
                    }`}>
                      {line.lineNumber}
                    </td>
                    <td className={`code-content ${
                      line.status === 'unchanged' ? 'code-content-unchanged' : 
                      line.status === 'highlighted' ? 'code-content-highlighted' :
                      line.status === 'del' ? 'code-content-deletion' :
                      line.status === 'add' ? 'code-content-addition' : ''
                    }`}>
                      <code className={`block whitespace-pre-wrap ${
                        showDiff && line.status === 'del' ? 'line-strikethrough' : ''
                      }`}>
                        {line.content}
                      </code>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CodeRenderer;
