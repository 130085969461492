import React, { useState, useEffect, useRef, useContext } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useNavigate } from 'react-router-dom';
import Explorer from "../../components/Explorer/Explorer";
import Logo from '../../components/ui/Logo';
import ChatInterface from './ChatInterface';
import FileList from './FileList';
import { AppContext } from '../../context/AppContext';

const OrientationPage = () => {
  const [messages, setMessages] = useState([
    { sender: 'system', text: "Welcome! What's the next thing you want to implement?" }
  ]);
  const [isThinking, setIsThinking] = useState(false);
  const [primaryFiles, setPrimaryFiles] = useState([]);
  const [secondaryFiles, setSecondaryFiles] = useState([]);
  const [processedCount, setProcessedCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showAnalysisButton, setShowAnalysisButton] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [showSecondaryFiles, setShowSecondaryFiles] = useState(false);
  const navigate = useNavigate();
  const currentIndexRef = useRef(0);
  
  // Get WebSocket context
  const { 
    sendMessage, 
    wsConnected, 
    setFeatureRequestText,
    isMock,
    relevantFilesContent,
    currentStatus,
    ProcessingStatus
  } = useContext(AppContext);

  // Handle WebSocket responses
  useEffect(() => {
    if (relevantFilesContent) {
      console.log('Received files from WebSocket:', relevantFilesContent);
      
      // Check for DONE message
      if (relevantFilesContent === "DONE") {
        setShowAnalysisButton(true);
        setIsThinking(false);
        return;
      }

      if (typeof relevantFilesContent === 'string') {
        console.log(relevantFilesContent);
        return;
      }

      // Process the files
      processNextFile(relevantFilesContent);
    }
  }, [relevantFilesContent]);

  // When files are processed, pre-select the likely ones
  useEffect(() => {
    if (primaryFiles.length > 0) {
      setSelectedFiles(primaryFiles.map(file => file.path));
    }
  }, [primaryFiles]);

  const handleFileSelect = (filePath) => {
    setSelectedFiles(prev => 
      prev.includes(filePath)
        ? prev.filter(path => path !== filePath)
        : [...prev, filePath]
    );
  };

  const handleSendMessage = async (message) => {
    // Update UI state
    setMessages([...messages, { sender: 'user', text: message }]);
    setIsThinking(true);
    setPrimaryFiles([]);
    setSecondaryFiles([]);
    setProcessedCount(0);
    setShowAnalysisButton(false);
    currentIndexRef.current = 0;

    // Send message through WebSocket
    if (wsConnected) {
      sendMessage(message);
    } else {
      setMessages(prev => [...prev, { 
        sender: 'system', 
        text: "Error: WebSocket connection not available" 
      }]);
      setIsThinking(false);
    }

    setFeatureRequestText(message); // Store the original request
  };

  const processNextFile = (fileData) => {
    console.log('Processing file:', fileData);
    
    // If fileData is an object with Files property, process the array
    if (fileData.Files && Array.isArray(fileData.Files)) {
      fileData.Files.forEach(file => {
        setProcessedFiles(prev => [...prev, file]);
        
        // Categorize files based on relevancy
        if (file.relevancy === 'likely') {
          setPrimaryFiles(prev => [...prev, {
            path: file.file_path,
            description: file.textual_representation
          }]);
        } else if (file.relevancy === 'possible') {
          setSecondaryFiles(prev => [...prev, {
            path: file.file_path,
            description: file.textual_representation
          }]);
        }
      });
    }
    // If fileData is a single file object, process it directly
    else if (fileData.file_path) {
      setProcessedFiles(prev => [...prev, fileData]);
      
      // Categorize single file based on relevancy
      if (fileData.relevancy === 'likely') {
        setPrimaryFiles(prev => [...prev, {
          path: fileData.file_path,
          description: fileData.textual_representation
        }]);
      } else if (fileData.relevancy === 'possible') {
        setSecondaryFiles(prev => [...prev, {
          path: fileData.file_path,
          description: fileData.textual_representation
        }]);
      }
    }
  };

  const handleStartAnalysis = () => {
    const searchParams = isMock ? '?mock=true' : '';
    navigate(`/analysis${searchParams}`, { 
      state: { 
        selectedFiles: selectedFiles,
        originalRequest: messages.find(m => m.sender === 'user')?.text || ''
      } 
    });
  };

  // Add effect to handle status changes
  useEffect(() => {
    if (currentStatus) {
      let statusMessage;
      switch (currentStatus) {
        case ProcessingStatus.START_RELEVANT_FILES:
          statusMessage = "Finding relevant files...";
          break;
        case ProcessingStatus.START_SUGGEST_EDITS:
          statusMessage = "Analyzing code changes...";
          setIsThinking(false);
          setShowAnalysisButton(true);
          break;
        case ProcessingStatus.START_DEPENDENCY_IMPACT:
          statusMessage = "Analyzing dependency impact...";
          break;
        case ProcessingStatus.DONE:
          statusMessage = "Analysis complete!";
          setIsThinking(false);
          break;
        default:
          return;
      }
      
      setMessages(prev => {
        const filteredMessages = prev.filter(msg => 
          msg.sender !== 'status'
        );
        return [...filteredMessages, { 
          sender: 'status', 
          text: statusMessage 
        }];
      });
    }
  }, [currentStatus]);

  return (
    <div className="h-screen bg-gray-900 p-4">
      <PanelGroup direction="horizontal" className="h-full">
        {/* Left Panel - Explorer */}
        <Panel defaultSize={30} minSize={20} className="flex flex-col">
          <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
            <Explorer />
          </div>
        </Panel>
        
        <PanelResizeHandle className="w-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />
        
        {/* Right Panel - Chat and Files */}
        <Panel defaultSize={70} minSize={60}>
          <div className="h-full pl-4 flex flex-col overflow-hidden">
            {/* Chat Interface */}
            <div className="bg-gray-800 rounded-lg border border-gray-700 mb-4">
              <ChatInterface 
                onSendMessage={handleSendMessage} 
                messages={messages} 
                isThinking={isThinking} 
              />
            </div>

            {/* File Lists */}
            <div className="flex-1 bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
              <div className="h-full overflow-y-auto p-4">
                {primaryFiles.length > 0 && (
                  <>
                    <h3 className="text-xl font-semibold text-gray-200 mb-4 border-b border-gray-700 pb-2">
                      Likely Relevant Files
                    </h3>
                    <FileList
                      files={primaryFiles}
                      isProcessing={isThinking}
                      selectedFiles={selectedFiles}
                      onFileSelect={handleFileSelect}
                    />
                    
                    {secondaryFiles.length > 0 && (
                      <div className="mt-6">
                        <h3 
                          className="text-xl font-semibold text-gray-200 mb-4 border-b border-gray-700 pb-2 cursor-pointer flex items-center"
                          onClick={() => setShowSecondaryFiles(!showSecondaryFiles)}
                        >
                          <span className="mr-2 text-blue-400">
                            {showSecondaryFiles ? '▼' : '▶'}
                          </span>
                          Potentially Relevant Files
                        </h3>
                        {showSecondaryFiles && (
                          <FileList
                            files={secondaryFiles}
                            isProcessing={isThinking}
                            selectedFiles={selectedFiles}
                            onFileSelect={handleFileSelect}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Analysis Button */}
            {showAnalysisButton && (
              <div className="mt-4">
                <button
                  onClick={handleStartAnalysis}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Start Analysis
                </button>
              </div>
            )}
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default OrientationPage;
