import React, { useMemo, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { GraphVisualization, Legend } from './GraphVisualization';
import { graphData } from '../../content/datastore';

const GraphDisplay = ({ showLegend = true }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const processedData = useMemo(() => {
    if (!graphData) return { nodes: [], edges: [], legend: { nodes: [], edges: [] } };
    return graphData;
  }, []);

  const visualizationData = {
    nodes: processedData.nodes || [],
    edges: processedData.edges || []
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className={`bg-gray-900 text-white rounded-lg border border-gray-700 overflow-hidden ${isFullScreen ? 'fixed inset-0 z-50' : 'h-full'}`}>
      <PanelGroup direction="horizontal">
        <Panel defaultSize={80} minSize={50}>
          <div className="h-full relative">
            <GraphVisualization 
              graphData={visualizationData} 
              isFullScreen={isFullScreen}
            />
            <button
              onClick={toggleFullScreen}
              className="absolute top-4 right-4 bg-gray-700 text-white px-3 py-1.5 rounded-md hover:bg-gray-600 border border-gray-600"
            >
              {isFullScreen ? 'Minimize' : 'Expand'}
            </button>
          </div>
        </Panel>
        
        {showLegend && (
          <>
            <PanelResizeHandle className="w-1.5 bg-gray-800 hover:bg-blue-500 transition-colors" />
            <Panel defaultSize={20} minSize={10}>
              <div className="h-full border-l border-gray-700 bg-gray-800/50">
                <Legend legend={processedData.legend} />
              </div>
            </Panel>
          </>
        )}
      </PanelGroup>
    </div>
  );
};

export default GraphDisplay;
