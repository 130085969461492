// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes, useSearchParams } from "react-router-dom";
import Analysis from "./pages/analysis/Analysis";
import GraphDataVisualization from "./pages/GraphPageForTesting";
import OrientationPage from "./pages/orientation/OrientationPage";
import { AppProvider } from "./context/AppContext";
import './pages/analysis/FeatureRequest/sharedStyles.css';

// Create a wrapper component that uses hooks
const AppContent = () => {
  const [searchParams] = useSearchParams();
  const isMock = searchParams.get('mock') === 'true';

  return (
    <AppProvider isMock={isMock}>
      <Routes>
        <Route path="/" element={<OrientationPage />} />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/graph-data-visualization" element={<GraphDataVisualization />} />
      </Routes>
    </AppProvider>
  );
};

// Main App component
const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
