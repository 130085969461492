import React, { createContext, useState, useRef, useEffect, useCallback } from 'react';

const ProcessingStatus = {
  START_RELEVANT_FILES: 'start_relevant_files',
  START_SUGGEST_EDITS: 'start_suggest_edits',
  START_DEPENDENCY_IMPACT: 'start_dependency_impact',
  DONE: 'done'
};

const ContentType = {
  STATUS: 'status',
  RELEVANT_FILES: 'relevant_files',
  SUGGEST_EDITS: 'suggest_edits',
  DEPENDENCY_IMPACT: 'dependency_impact'
};

export const AppContext = createContext();

export const AppProvider = ({ children, isMock }) => {
  const [featureRequestText, setFeatureRequestText] = useState(
    "Implement a minimum balance requirement for specific account types to help customers avoid fees, qualify for interest, and ensure compliance with banking policies. Key Details: Minimum Balance by Account Type: Define and enforce minimum balances for accounts (e.g., savings, checking) to provide fee waivers or interest eligibility for qualifying customers."
  );
  const [wsConnected, setWsConnected] = useState(false);
  const [expandedCards, setExpandedCards] = useState([]);
  const [mainPanelSizes, setMainPanelSizes] = useState([70, 30]);
  const wsRef = useRef(null);
  const retryCountRef = useRef(0);
  const maxRetries = 5;
  
  // State for different content types
  const [relevantFilesContent, setRelevantFilesContent] = useState(null);
  const [analysisContent, setAnalysisContent] = useState(null);
  const [dependencyImpactContent, setDependencyImpactContent] = useState({
    dependency_impacts: []
  });
  const [currentStatus, setCurrentStatus] = useState(null);
  const [accumulatedFiles, setAccumulatedFiles] = useState({});

  useEffect(() => {
    const connectWebSocket = () => {
      if (retryCountRef.current >= maxRetries) {
        console.error('Max retries reached for WebSocket.');
        return;
      }

      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      const websocketUrl = process.env.NODE_ENV === 'development' 
        ? `ws://localhost:80/ws${isMock ? '?mock=true' : ''}`
        : `${protocol}://${window.location.host}/ws${isMock ? '?mock=true' : ''}`;

      wsRef.current = new WebSocket(websocketUrl);

      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
        setWsConnected(true);
        retryCountRef.current = 0;
      };

      wsRef.current.onmessage = (event) => {
        console.log('Message received:', event.data);
        const response = JSON.parse(event.data);
        
        switch (response.content_type) {
          case ContentType.STATUS:
            setCurrentStatus(response.content);
            break;
          
          case ContentType.RELEVANT_FILES:
            const filesArray = Array.isArray(response.content.Files) 
              ? response.content.Files 
              : [response.content.Files];
            
            setAccumulatedFiles(prev => ({
              ...prev,
              ...filesArray.reduce((acc, file) => ({
                ...acc,
                [file.file_path]: file
              }), {})
            }));
            
            setRelevantFilesContent(response.content);
            break;
          
          case ContentType.SUGGEST_EDITS:
            setAnalysisContent(response.content);
            break;
          
          case ContentType.DEPENDENCY_IMPACT:
            console.log('Received dependency impact:', response.content);
            setDependencyImpactContent(prev => ({
              dependency_impacts: [
                ...prev.dependency_impacts,
                response.content
              ]
            }));
            break;
          
          default:
            console.warn('Unknown content type:', response.content_type);
        }
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setWsConnected(false);
      };

      wsRef.current.onclose = (event) => {
        console.log('WebSocket closed:', event);
        setWsConnected(false);
        if (event.code !== 1000) {
          retryCountRef.current += 1;
          setTimeout(connectWebSocket, 1000);
        }
      };
    };

    if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
      connectWebSocket();
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close(1000, 'Component unmounting');
      }
    };
  }, [isMock]);

  const sendMessage = useCallback((message, files) => {
    console.log('Sending message:', { message, files });
    
    if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
      console.error('WebSocket is not connected');
      return;
    }

    try {
      wsRef.current.send(JSON.stringify({
        message,
        files
      }));
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }, []);

  const toggleCard = (cardId) => {
    setExpandedCards(prev => 
      prev.includes(cardId) 
        ? prev.filter(id => id !== cardId)
        : [...prev, cardId]
    );
  };

  const getCode = async (filePath) => {
    const url = `/get_code/${encodeURIComponent(filePath)}${isMock ? '?mock=true' : ''}`;
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    return data.content;
  };

  useEffect(() => {
    return () => {
      // Don't clear accumulated files on unmount
      // This allows the files to persist between pages
    };
  }, []);

  const clearAccumulatedFiles = () => {
    // Only clear when explicitly called, not on component unmount
    setAccumulatedFiles({});
  };

  return (
    <AppContext.Provider value={{
      wsConnected,
      sendMessage,
      expandedCards,
      toggleCard,
      mainPanelSizes,
      setMainPanelSizes,
      featureRequestText,
      setFeatureRequestText,
      relevantFilesContent,
      analysisContent,
      dependencyImpactContent,
      currentStatus,
      ProcessingStatus,
      ContentType,
      getCode,
      isMock,
      clearAccumulatedFiles,
      accumulatedFiles,
    }}>
      {children}
    </AppContext.Provider>
  );
};