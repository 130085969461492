import React, { useState } from 'react';

const Tooltip = ({ children, content, title }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-50 p-3 bg-gray-800 text-white text-sm rounded-md shadow-lg mt-2 left-0 w-max max-w-md">
          {title && <div className="font-bold mb-1 text-gray-200">{title}</div>}
          <div className="text-gray-100">{content}</div>
          <div className="absolute -top-2 left-3 w-3 h-3 bg-gray-800 transform rotate-45"></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
