import codeJson from './code.json';
import relevantFilesJson from './relevant_files.json';
import changesJson from './changes.json';
import dependencyJson from './dependency.json';
import graphJson from './graph.json';
import { parseGraphData } from '../components/graphs/graph_parsing';

console.log('Loaded changes:', changesJson);

export const codeContent = codeJson;
export const relevantFiles = relevantFilesJson;
export const changes = changesJson;
export const dependencies = dependencyJson; 
export const graphData = parseGraphData(graphJson);