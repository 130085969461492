import React, { useEffect, useRef } from 'react';
import Graph from 'react-graph-vis';

export function GraphVisualization({ graphData = { nodes: [], edges: [] }, isFullScreen }) {
  const graphRef = useRef(null);

  useEffect(() => {
    if (graphRef.current && graphRef.current.Network) {
      graphRef.current.Network.setSize('100%', '100%');
      graphRef.current.Network.fit();
    }
  }, [isFullScreen]);

  const options = {
    layout: {
      hierarchical: false
    },
    edges: {
      color: '#ffffff',
      arrows: {
        to: { enabled: true, type: 'arrow' },
        middle: { enabled: false },
        from: { enabled: false }
      },
      smooth: {
        type: 'cubicBezier',
        forceDirection: 'horizontal',
        roundness: 0.4
      }
    },
    nodes: {
      shape: 'dot',
      size: 16,
      font: {
        size: 12,
        color: '#ffffff',
        align: 'center'
      },
      borderWidth: 2
    },
    height: '100%',
    physics: {
      enabled: true,
      stabilization: {
        iterations: 1000,
        updateInterval: 25
      }
    },
    interaction: {
      hover: true,
      navigationButtons: true,
      keyboard: true
    }
  };

  const events = {
    select: (event) => {
      const { nodes, edges } = event;
      console.log('Selected nodes:', nodes);
      console.log('Selected edges:', edges);
    },
    hoverNode: (event) => {
      console.log('Hovered node:', event);
    }
  };

  const visGraphData = {
    nodes: (graphData?.nodes || []).map(node => ({
      id: node.id,
      label: node.properties?.name || node.label,
      color: node.color,
      value: node.properties?.size || 5,
      title: `Type: ${node.label}<br>` + 
             Object.entries(node.properties || {})
               .map(([key, value]) => `${key}: ${value}`)
               .join('<br>')
    })),
    edges: (graphData?.edges || []).map((edge) => ({
      id: edge.id,
      from: edge.source,
      to: edge.target,
      label: edge.properties?.description || edge.type,
      color: edge.color,
      arrows: 'to',
      title: `Type: ${edge.type}<br>` + 
             Object.entries(edge.properties || {})
               .map(([key, value]) => `${key}: ${value}`)
               .join('<br>')
    }))
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Graph
        ref={graphRef}
        graph={visGraphData}
        options={options}
        events={events}
        style={{ height: '100%' }}
      />
    </div>
  );
}

export function Legend({ legend = { nodes: [], edges: [] } }) {
  return (
    <div className="legend h-full flex flex-col">
      <h3 className="text-sm font-semibold p-2 border-b border-gray-700">Legend</h3>
      <div className="flex-grow overflow-y-auto">
        <div className="p-2">
          <h4 className="text-xs font-medium mb-1">Nodes</h4>
          {(legend.nodes || []).map(({ type, color, count }) => (
            <div key={type} className="flex items-center mb-1">
              <div style={{ width: 12, height: 12, backgroundColor: color }} className="mr-2 rounded-full"></div>
              <span className="text-xs">{type} ({count})</span>
            </div>
          ))}
        </div>
        <div className="p-2 border-t border-gray-700">
          <h4 className="text-xs font-medium mb-1">Edges</h4>
          {(legend.edges || []).map(({ type, color, count }) => (
            <div key={type} className="flex items-center mb-1">
              <div style={{ width: 12, height: 3, backgroundColor: color }} className="mr-2"></div>
              <span className="text-xs">{type} ({count})</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
