import { graphData } from '../../content/datastore';

const COLOR_PALETTE = [
  '#4CAF50', '#2196F3', '#FFC107', '#9C27B0', '#FF5722',
  '#00BCD4', '#FF9800', '#795548', '#607D8B', '#E91E63'
];

function assignColors(types) {
  const sortedTypes = [...types.entries()].sort((a, b) => b[1] - a[1]);
  const colorMap = new Map();
  sortedTypes.forEach(([type, count], index) => {
    colorMap.set(type, COLOR_PALETTE[index % COLOR_PALETTE.length]);
  });
  return colorMap;
}

export function parseGraphData(data) {
  const nodes = new Map();
  const edges = [];
  const nodeTypes = new Map();
  const edgeTypes = new Map();

  // Process nodes
  data.nodes.forEach(node => {
    const nodeType = node.attributes.type;
    nodes.set(node.id, {
      id: node.id,
      label: nodeType,
      properties: { ...node.attributes }
    });
    nodeTypes.set(nodeType, (nodeTypes.get(nodeType) || 0) + 1);
  });

  // Process edges
  data.edges.forEach(edge => {
    const edgeType = edge.attributes.type;
    const newEdge = {
      id: `${edge.from}_${edgeType}_${edge.to}`,
      source: edge.from,
      target: edge.to,
      type: edgeType,
      properties: { ...edge.attributes }
    };
    edges.push(newEdge);
    edgeTypes.set(edgeType, (edgeTypes.get(edgeType) || 0) + 1);
  });

  const nodeColorMap = assignColors(nodeTypes);
  const edgeColorMap = assignColors(edgeTypes);

  // Assign colors
  nodes.forEach(node => {
    node.color = nodeColorMap.get(node.label);
  });

  edges.forEach(edge => {
    edge.color = edgeColorMap.get(edge.type);
  });

  return {
    nodes: Array.from(nodes.values()),
    edges,
    legend: {
      nodes: Array.from(nodeTypes.entries()).map(([type, count]) => ({
        type,
        color: nodeColorMap.get(type),
        count
      })),
      edges: Array.from(edgeTypes.entries()).map(([type, count]) => ({
        type,
        color: edgeColorMap.get(type),
        count
      }))
    }
  };
} 