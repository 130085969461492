import React, { useState, useEffect } from 'react';

const FileList = ({ files, isProcessing }) => {
  return (
    <div className="space-y-3">
      {files.map((file, index) => {
        const fileName = file.path.split('/').pop();
        
        return (
          <div 
            key={index}
            className="relative rounded-lg bg-gray-800 p-4 hover:bg-gray-750 transition-colors"
          >
            <div className="flex items-start">
              <div className="flex-1">
                <h4 className="text-sm font-medium text-blue-400 mb-2">
                  {fileName}
                </h4>
                <p className="text-sm text-gray-300">
                  {file.description}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FileList;
