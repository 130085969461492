import React from 'react';
import { 
  FileIcon, 
  FolderIcon,
  FileTextIcon,
  FileJsonIcon,
  FileCodeIcon,
  LayoutTemplate,
  Settings,
  Binary,
  Coffee
} from 'lucide-react';

export const getFileIcon = (node) => {
  if (node.type === 'folder') {
    return <FolderIcon size={16} className="text-gray-500" />;
  }

  const extension = node.name.split('.').pop().toLowerCase();
  
  switch (extension) {
    case 'java':
      return <Coffee size={16} className="text-yellow-400" />;
    case 'html':
      return <LayoutTemplate size={16} className="text-yellow-400" />;
    case 'xml':
      return <Settings size={16} className="text-yellow-400" />;
    case 'js':
    case 'jsx':
      return <FileCodeIcon size={16} className="text-yellow-400" />;
    case 'cpy':
    case 'cbl':
      return <Binary size={16} className="text-yellow-400" />;
    case 'json':
      return <FileJsonIcon size={16} className="text-yellow-400" />;
    case 'md':
    case 'txt':
      return <FileTextIcon size={16} className="text-yellow-400" />;
    default:
      return <FileIcon size={16} className="text-yellow-400" />;
  }
}; 